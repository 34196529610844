import { useState } from 'react';
import { AccordionItem, AccordionItemType } from './AccordionItem';
import { AccordionSlider } from './AccordionSlider';

interface IAccordion {
  icon?: boolean;
  data: AccordionItemType[];
  variant?: {
    type: 'list' | 'slider';
    meta?: Record<string, any>;
  };
  lastRow?: {
    title: string;
    onClick: () => void;
  };
  handleOpen?: (id: number) => void;
  handleClose?: () => void;
  handleHoverEnter?: (id: number) => void;
  handleHoverExit?: () => void;
}

export const Accordion: React.FC<IAccordion> = ({
  data,
  icon = true,
  variant = { type: 'list' },
  lastRow,
  handleOpen,
  handleClose,
  handleHoverEnter,
  handleHoverExit
}) => {
  const [opened, setOpened] = useState(-1);

  const open = (id: number) => {
    if (id === opened) {
      setOpened(-1);

      if (handleClose) {
        handleClose();
      }
      return;
    }

    setOpened(id);

    if (handleOpen) {
      handleOpen(id);
    }
  };

  return (
    <>
      {variant.type === 'slider' && (
        <AccordionSlider data={data} config={variant.meta} />
      )}
      {variant.type === 'list' && (
        <>
          {data.map((item) => (
            <AccordionItem
              key={item.id}
              icon={icon}
              item={item}
              open={open}
              opened={opened === item.id}
              hoverEnter={handleHoverEnter}
              hoverExit={handleHoverExit}
            />
          ))}
          {lastRow && (
            <AccordionItem
              icon={false}
              opened={false}
              item={{
                id: -1,
                title: () => lastRow.title,
              }}
              open={lastRow.onClick}
            />
          )}
        </>
      )}
    </>
  );
};
