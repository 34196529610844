import React from 'react';
import { motion } from 'framer-motion';

import clsx from 'clsx';
import styles from './AccordionHeader.module.scss';
import { Link } from 'react-router-dom';

const Icon: React.FC<{
  open: boolean;
}> = ({ open }) => {
  return (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg"
         className={clsx(styles.icon)}>
      <motion.path
        transition={{ duration: 0.5 }}
        initial={{
          d: 'M31.6 18V19.7H20.8985L35 33.8015L33.8015 35L19.7 20.8985V31.6H18V18H31.6Z',
        }}
        animate={{
          d: !open ? 'M21.4 18V19.7H32.1015L18 33.8015L19.1985 35L33.3 20.8985V31.6H35V18H21.4Z' : 'M31.6 18V19.7H20.8985L35 33.8015L33.8015 35L19.7 20.8985V31.6H18V18H31.6Z',
        }}
        fill="currentColor"
      />
      <circle cx="27" cy="27" r="26" stroke="currentColor"/>
    </svg>
  );
};

export const AccordionHeader: React.FC<{
  open: boolean;
  children: React.ReactNode;
  icon: boolean;
  link?: string;
  isServices?: boolean;
}> = ({ open, children, icon, link, isServices }) => {
  return (
    <div className={clsx(styles.header)}>
      {link ? (
        <Link to={link}>
          <div className={clsx(styles['header__title'])}>{children}</div>
          {icon && <Icon open={open}/>}
        </Link>
      ) : (
        <>
          <div
            className={clsx(styles['header__title'], {
              [styles['header__title-services']]: isServices
            })}
          >
            {children}
          </div>
          {icon && <Icon open={open}/>}
        </>
      )}
    </div>
  );
};
