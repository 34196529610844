import React, { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { useSelector } from '@/store';
import {
  ConditionalWrapper,
  AnimatedBlock,
  AnimatedTitle,
  Accordion,
} from '@/components';
import { useMediaQuery } from '@/hooks';
import { RefsContext } from '@/providers';

import styles from './Services.module.scss';

export const Services: React.FC<{ animate?: boolean }> = ({ animate }) => {
  const { servicesRef } = useContext(RefsContext);
  const { services } = useSelector((store) => store.home.ru);
  const matchesTablet = useMediaQuery('tablet');

  // TODO: заменить флаг скрыть \ показать цены и саму стоимость как будет готов бэк.
  const mapServices = useMemo(() => services?.map((elem, index) => {
    let price = '';

    switch (index) {
      case 0: {
        price = '3100-3500 ₽/час';
        break;
      }
      case 1: {
        price = '3000-3500 ₽/час';
        break;
      }
      case 2: {
        price = '3000-3500 ₽/час';
        break;
      }
      case 3: {
        price = '3000-3700 ₽/час';
        break;
      }
      case 4: {
        price = '2500 ₽/час';
        break;
      }
      case 5: {
        price = '3200-3900 ₽/час';
      }
    }

    return { ...elem, showPrice: true, price };
  }) ?? [], [services]);

  return (
    <div className={clsx(styles.services)} ref={servicesRef}>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedTitle>{wrappedChildren}</AnimatedTitle>
        )}
      >
        <h2 className={clsx(styles['services__title'])}>Технологии</h2>
      </ConditionalWrapper>
      <ConditionalWrapper
        condition={!!animate}
        wrap={(wrappedChildren) => (
          <AnimatedBlock>{wrappedChildren}</AnimatedBlock>
        )}
      >
        <Accordion
          data={mapServices.map((s, i) => ({
            className: styles['services__accordion'],
            id: i + 1,
            title: s?.name,
            body: '',
            stack: matchesTablet ? [] : s?.list.map((l) => l.name),
            list: matchesTablet ? s?.list.map((l) => l.name) : [],
            price: s?.price,
            showPrice: s?.showPrice,
            inlineList: matchesTablet,
            unopeneble: !matchesTablet,
            borderStyle: 'dotted',
          }))}
          icon={matchesTablet}
        />
      </ConditionalWrapper>
    </div>
  );
};
